/* SplashScreen.css */
.splash-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #fff; /* Change this to your desired background color */
  }
  
  .splash-logo {
    width: 150px; /* Adjust the size of the logo as needed */
    height: auto;
  }